<template>
  <page-wrapper
    :title="title"
    :text="text"
    :click="!isStep1"
    @click="onChangeStep(1)"
  >
    <template v-if="!isAuth">
      <user-form
        v-if="isStep1"
        :obj="form"
        :load="isLoading"
        @set="onSetField"
        @next="preValidate"
      />
      <send-message-form
        v-else
        :time-to-resend="timeToResend"
        @set="onSetField"
        @resend="sendingSms"
      />
    </template>
  </page-wrapper>
</template>

<script>
import { mapActions } from 'vuex'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import UserForm from '@/components/TablesFormsFilters/UserForm'
import SendMessageForm from './SendMessageForm'
import { titles, texts } from './constants'

export default {
  name: 'Registration',
  components: { PageWrapper, UserForm, SendMessageForm },
  data() {
    return {
      step: 1,
      isLoading: false,
      timeToResend: 0,
      form: {
        first_name: '',
        last_name: '',
        login: '',
        email: '',
        code: '',
        org_id: ''
      }
    }
  },
  computed: {
    title() {
      return titles[this.isAuth ? 1 : 0]
    },
    text() {
      const key = this.isAuth ? 'authed' : this.step
      return texts[key].text
    },
    isStep1() {
      return this.step === 1
    }
  },
  methods: {
    ...mapActions('auth', ['registration', 'sendSms', 'preRegistration']),
    onChangeStep(step) {
      this.step = step
    },
    onSetField({ val, key }, flag = 0) {
      this.form[key] = val
      if (flag) this.registration(this.form)
    },
    async preValidate() {
      this.isLoading = true
      const res = await this.preRegistration(this.form)
      if (res !== 'error') {
        this.sendingSms()
      } else {
        this.isLoading = false
      }
    },
    sendingSms() {
      this.isLoading = true
      this.sendSms(this.form.login)
        .then(({ text, status, time_to_resend }) => {
          this.$notify.closeAll()
          if (status === 'error') {
            this.errHandler(text)
          } else if (text) {
            this.$notify({
              message: text,
              title: 'Отправлено',
              type: 'success',
              duration: 0
            })
          }
          this.timeToResend = time_to_resend
          this.onChangeStep(2)
        })
        .catch(err => this.errHandler(err))
        .finally(() => (this.isLoading = false))
    }
  },
  beforeDestroy() {
    this.$notify.closeAll()
  }
}
</script>
