export const titles = [
  'РЕГИСТРАЦИЯ НОВОГО ПЕРЕВОЗЧИКА',
  'Вы зарегистрированы как пееревозчик'
]

export const texts = {
  1: {
    text:
      'Регистрация доступна для новых перевозчиков. Если у вас заключен договор то'
  },
  2: { text: 'Назад к регистрационной форме' },
  authed: { text: 'Для получения уведомлений по почте. Подтвердите ее.' }
}
