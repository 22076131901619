<template>
  <section class="send-message-form i-components">
    <p class="fs-20 t-greyDark mb-30">
      Мы отправили вам код в SMS сообщении.<br />
      Введите его
    </p>
    <div class="form-block mb-40">
      <div class="fb-div">
        <el-input
          v-model="code"
          v-mask="'######'"
          autocomplete="nope"
          :class="['form-block__input mr-30', { error: !!authStatus }]"
          @input="onInput"
          @keyup.enter.native="onNext"
        />
        <span v-if="!!authStatus" class="declined fs-13">{{ authStatus }}</span>
      </div>
      <i-button
        v-if="!isResendButton"
        :loading="loading"
        :disabled="loading"
        text="Далее"
        width="165"
        fs="16"
        @click="onNext"
      />
    </div>
    <p class="t-green fs-14 mt-20">
      SMS не пришло? Повторить отправку можно через
    </p>
    <p class="t-green timer mt-10 ta-c">
      <i-button
        v-if="isResendButton"
        :loading="loading"
        :disabled="loading"
        text="Повторить отправку SMS"
        width="250"
        fs="14"
        @click="$emit('resend')"
      />
      <span>{{ counter | toTimer }}</span>
    </p>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import IButton from '@/components/Ui/Button'

export default {
  name: 'SendMessageForm',
  components: { IButton },
  props: { timeToResend: Number },
  data() {
    return {
      code: '',
      counter: 120,
      timer: null,
      isResendButton: false
    }
  },
  watch: {
    timeToResend: {
      immediate: true,
      handler(val) {
        this.counter = val
        this.isResendButton = false
        this.checkTimer()
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['authStatus', 'getLoading']),
    loading() {
      return this.getLoading() || this.getLoading('Reg')
    }
  },
  methods: {
    ...mapMutations({ setStatus: 'auth/setStatus' }),
    onInput() {
      if (this.authStatus) this.setStatus()
    },
    onNext() {
      const { code } = this
      if (code.length === 6) {
        this.$emit('set', { val: code, key: 'code' }, 1)
      } else {
        this.$message.warning('Код должен состоять из 6 цифр')
      }
    },
    checkTimer() {
      if (this.counter) {
        this.timer = setTimeout(() => {
          --this.counter
          this.checkTimer()
        }, 1000)
      } else this.isResendButton = true
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.send-message-form
  @include to(768px)
    padding: 0 20px
  .form-block
    display: flex
    align-items: center
    flex-wrap: wrap
    .fb-div
      position: relative
      span
        position: absolute
        bottom: -20px
        left: 20px
    .el-input
      width: 320px
      @include to(610px)
        width: 100%
      &.error input
        border: 1px solid red
    &__input
      @include to(610px)
        margin-right: 0
        margin-bottom: 21px
    .el-input__inner
      text-align: center
  .timer
    font-size: 25px
    line-height: 29px
    width: 320px
</style>
